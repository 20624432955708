import React, { useState, useEffect, useCallback } from 'react';
import { SkeletonLoader } from '@hpstellar/core';

import { EtrAPIClient } from '../../core/src/etr-wcs-api-client';

const etrAPIClient = new EtrAPIClient();

const DESCRIPTION_CONTAINER_STYLE = { overflow: 'hidden', maxHeight: '105%' };

const Loading = () => {
    return (
        <div style={DESCRIPTION_CONTAINER_STYLE}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, i) => (
                <SkeletonLoader style={{ margin: '4px 0px', maxHeight: '10px' }} key={i} />
            ))}
        </div>
    );
};

const getProductOverview = path => {
    return etrAPIClient.component
        .get('product', 'product-overview', { path })
        .then(({ productOverview }) => {
            const [content = {}, list = {}] = productOverview || [];
            const { description } = content;
            let { specs } = list;
            return { description, specs };
        })
        .catch(err => {
            return {};
        });
};

/**
 * Hook for managing product details state. Useful if you want to prevent unnecessary API calls.
 */
export const useSeeMoreState = () => {
    const [shouldFetchProductDetails, setProductDetails] = useState({});

    const getDetailsClick = useCallback(product => {
        return e => {
            const { sku } = product || {};
            if (sku && !(sku in shouldFetchProductDetails)) {
                setProductDetails({ ...shouldFetchProductDetails, [sku]: true });
            }
        };
    });
    const getProductInfo = product => {
        const { sku } = product || {};
        if (sku && sku in shouldFetchProductDetails) {
            return product;
        }
        return null;
    };

    return [getProductInfo, getDetailsClick];
};

/**
 *
 * @param {*} param0
 * @returns
 */
export default ({ children, productInfo }) => {
    const { productUrl, sku } = productInfo || {};
    const [productDetails, setProductDetails] = useState({});

    useEffect(() => {
        if (productUrl && !productDetails[sku]) {
            const [base, slug] = productUrl.split('/pdp/');
            const path = `pdp/${slug}`;
            getProductOverview(path).then(({ description, specs }) => {
                //join description array on new line
                const descriptionText =
                    description?.length > 0
                        ? description?.join(`

                 `)
                        : specs?.length > 0
                          ? specs.join(`
                         `)
                          : null;
                setProductDetails({
                    ...productDetails,
                    [sku]: {
                        description: descriptionText,
                    },
                });
            });
        }
        return () => {};
    }, [productUrl]);

    if (!children) return null;

    return children({ ...(productDetails[sku] || { description: <Loading /> }) });
};
